import { useEffect, useState } from "react";
import Side from "./side";

const Users = ({page}) => {
    const [list, setList] = useState([]);
    useEffect(() => {
        let url = '/users';
        if (page === 'whatsapp') {
            url = '/whatsapps';
        }
        if (page === 'contacts') {
            url = '/contacts';
        }
        fetch('https://discovery.trishika.co'+url, {credentials: 'include'})
        .then(res => res.json())
        .then(jr => {
            let rum = <></>;
            if (page === 'users') {
                rum = jr.map(l => <><div className="col-4">{l.name}</div><div className="col-4">{l.email}</div>
                <div className="col-4">{l.mobileno}</div></>)
            }
            if (page === 'whatsapp') {
                rum = jr.map(l => <div className="col-12">{l.number}</div>);
            }
            if (page === 'contacts') {
                rum = jr.map(l => {
let c = (new Date(l.createdAt)); let dt = `${c.getDate()}-${c.getMonth()+1}-${c.getFullYear()} at ${c.getHours()}:${c.getMinutes()}`;
                    return <><div className="col-2">{l.name}</div>
                <div className="col-2">{l.phone}</div>
                <div className="col-2">{l.email}</div>
                <div className="col-4">{l.message}</div>
                <div className="col-2">{dt}</div>
                </>})
            }
            setList(rum);
        })
    }, [page])
    return <div className="row">
    <Side />
    <div className="col-10">
        {
        list.map((el, i) => <div key={i} className="row" style={{
            backgroundColor: "aliceblue", borderBottom: "1px solid #c3c3c3",
            borderTop: "1px solid #c3c3c3", marginBottom: "0.2rem"
        }}>{el}</div>)
        }
    </div>
    </div>
}

export default Users;