import { Link } from "react-router-dom";

const Side = () => {
    const logout = () => {
        fetch('https://discovery.trishika.co/auth/logmeout', {credentials: 'include'})
        .then(res => res.json()).then(jr => window.location.href = '/');
    }
return <>
<aside className="col-2">
    <a className="btn btn-primary w-100" href="/dashboard">Dashboard</a>
    <Link to="/pricing" className="btn w-100 btn-warning mt-3">Update Pricing</Link>
    <Link to="/users" className="btn w-100 btn-primary my-3">List Users</Link>
    <Link to="/whatsapp" className="btn w-100 btn-primary">WhatsApp signups</Link>
    <Link to="/contacts" className="btn w-100 btn-primary my-3">View Contacts</Link>
    <p className="mt-3"><b>Edit About Pages</b></p>
    <ul className="list-group">
        <li className="list-group-item"><Link to="/page-edit/about-us">About us</Link></li>
        <li className="list-group-item"><Link to="/page-edit/sambhav-diam">Sambhav Advantage</Link></li>
    </ul>
    <p className="mt-3"><b>Edit Policies</b></p>
    <ul className="list-group">
        <li className="list-group-item"><Link to="/page-edit/privacy-policy">Privacy Policy</Link></li>
        <li className="list-group-item"><Link to="/page-edit/terms-and-conditions">Terms and Conditions</Link></li>
        <li className="list-group-item"><Link to="/page-edit/shipping-policy">Shipping Policy</Link></li>
    </ul>
    <p className="mt-3"><b>Edit Guides</b></p>
    <ul className="list-group">
        <li className="list-group-item"><Link to="/page-edit/earrings-guide">Earrings Guide</Link></li>
        <li className="list-group-item"><Link to="/page-edit/diamond-care">Diamond Jewelry Care</Link></li>
        <li className="list-group-item"><Link to="/page-edit/ear-piercing">Ear Piercing</Link></li>
        <li className="list-group-item"><Link to="/page-edit/free-shipping">Free Shipping</Link></li>
        <li className="list-group-item"><Link to="/page-edit/faqs">FAQs</Link></li>
        <li className="list-group-item"><Link to="/page-edit/financing-options">Financing Options</Link></li>
    </ul>
    <button className="btn btn-dark mt-3" onClick={logout}>Logout</button>
</aside>
</>
}

export default Side;