import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Dashboard from './dashboard';
import Pageedit from './pageedit';
import Pricing from './pricing';
import Users from './users';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/page-edit/:page' element={<Pageedit />} />
      <Route path='/users' element={<Users page="users" />} />
      <Route path='/pricing' element={<Pricing />} />
      <Route path='/whatsapp' element={<Users page="whatsapp" />} />
      <Route path='/contacts' element={<Users page="contacts" />} />
    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
