import { useEffect, useState } from "react";
import Order from './order';

const Orderslist = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetch('https://discovery.trishika.co/orders-list', {headers: {'Content-Type':'application/json'},
credentials: 'include'})
    .then(res => (res.ok) ? res.json() : false).then(jr => { setOrders(jr); setLoading(false); });
    }, []);
    return (loading) ? <div className="col-6"><img src="/processing.gif" alt="loading" /></div>
    : <div className="col-10">{orders.map((orde, i) => <Order key={i} details={orde} />)}</div>
}

export default Orderslist;