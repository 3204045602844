import { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Editor from 'suneditor-react';
import Side from './side';

const Ep = () => {
    const [edit, setEdit] = useState();
    const {page} = useParams();
    const title = useRef();
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState('loading...');
    useEffect(() => {
        title.current.value = 'loading...';
        setLoading(true);
        fetch('https://discovery.trishika.co/page/'+page, {headers: {'Content-Type': 'application/json'}, credentials: 'include'})
        .then(res => res.json())
        .then(jr => {
            title.current.value = jr.title;
            setContent(jr.content); setLoading(false);
        })
    }, [page])
    const savepage = (evt) => {
        evt.target.innerHTML = "Saving...";
        evt.target.disabled = true;
        fetch('https://discovery.trishika.co/page/'+page, {headers: {'Content-Type': 'application/json'}, credentials: 'include',
    method: 'post', body: JSON.stringify({title: title.current.value, content: edit})})
        .then(res => res.json())
        .then(jr => {alert('Saved'); window.location.reload(); evt.target.disabled=false; evt.target.innerHTML = "Save"; })
    };
return <div className='row'>
<Side />
<div className='col-10'>
<input type="text" className='form-control my-2' ref={title} defaultValue="loading..." />
{
    (loading)
    ? <p>Loading editor...</p>
    : <Editor lang="en" setContents={content} setOptions={{ height: 400, buttonList: [
        ['undo', 'redo'], ['bold', 'italic', 'underline', 'horizontalRule'], ['fontSize', 'formatBlock']
    ]}} onChange={setEdit} />
}
<button className='btn btn-primary mt-2' onClick={savepage}>Save</button>
</div>
</div>;}

export default Ep;