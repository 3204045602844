import { useState } from 'react';
import Side from './side';

const Pricing = () => {
const [diamond1, setdiamond1] = useState('fetching...');
const [diamond2, setdiamond2] = useState('fetching...');
const [e_14kt, sete_14kt] = useState('fetching...');
const [e_18kt, sete_18kt] = useState('fetching...');
const [s_14kt, sets_14kt] = useState('fetching...');
const [s_18kt, sets_18kt] = useState('fetching...');

useState(() => {
fetch('https://discovery.trishika.co/allprices', {headers: {'Content-Type':'application/json'},
credentials: 'include'})
.then(res => (res.ok) ? res.json() : window.location.href = '/')
.then(jr => {
setdiamond1(jr[0].price);
setdiamond2(jr[1].price);
sete_14kt(jr[2].price);
sete_18kt(jr[3].price);
sets_14kt(jr[4].price);
sets_18kt(jr[5].price);
});}, []);

const updateprice = (product, price) => {
    fetch('https://discovery.trishika.co/priceupdate', {headers: {'Content-Type':'application/json'},
credentials: 'include', method: 'post', body: JSON.stringify({product: product, price: price})})
.then(res => alert('Updated'));
}

    return <div className="row">
        <Side />
        <div className="col-10">
            <h1 className='text-danger'>You are changing LIVE prices!</h1>
            
            <div className='form-group row m-3'>
                <div className='col-4'>
            <label htmlFor='diamond1'>Diamond EF-VVS</label>
            <input value={diamond1} type="number" id='diamond1' className='form-control' onChange={(e) => {
                setdiamond1(e.target.value);
            }} />
                </div>
                <div className='col-2'>
                <button className='btn btn-primary mt-4' id="D_EF-VVS" onClick={(evt) => updateprice(evt.target.id, diamond1)}>Update</button>
                </div>
            </div>

            <div className='form-group row m-3'>
                <div className='col-4'>
            <label htmlFor='diamond2'>Diamond IJ-SI</label>
            <input value={diamond2} type="number" id='diamond2' className='form-control' onChange={(e) => {
                setdiamond2(e.target.value);
            }} />
                </div>
                <div className='col-2'>
                <button className='btn btn-primary mt-4' id="D_IJ-SI" onClick={(evt) => updateprice(evt.target.id, diamond2)}>Update</button>
                </div>
            </div>

            <div className='form-group row m-3'>
                <div className='col-4'>
            <label htmlFor='e_14kt'>Extension 14kt</label>
            <input value={e_14kt} type="number" id='e_14kt' className='form-control' onChange={(e) => {
                sete_14kt(e.target.value);
            }} />
                </div>
                <div className='col-2'>
                <button className='btn btn-primary mt-4' id="J_14kt" onClick={(evt) => updateprice(evt.target.id, e_14kt)}>Update</button>
                </div>
            </div>

            <div className='form-group row m-3'>
                <div className='col-4'>
            <label htmlFor='e_18kt'>Extension 18kt</label>
            <input value={e_18kt} type="number" id='e_18kt' className='form-control' onChange={(e) => {
                sete_18kt(e.target.value);
            }} />
                </div>
                <div className='col-2'>
                <button className='btn btn-primary mt-4' id="J_18kt" onClick={(evt) => updateprice(evt.target.id, e_18kt)}>Update</button>
                </div>
            </div>

            <div className='form-group row m-3'>
                <div className='col-4'>
            <label htmlFor='s_14kt'>Stud 14kt</label>
            <input value={s_14kt} type="number" id='s_14kt' className='form-control' onChange={(e) => {
                sets_14kt(e.target.value);
            }} />
                </div>
                <div className='col-2'>
                <button className='btn btn-primary mt-4' id="S_14kt" onClick={(evt) => updateprice(evt.target.id, s_14kt)}>Update</button>
                </div>
            </div>

            <div className='form-group row m-3'>
                <div className='col-4'>
            <label htmlFor='s_18kt'>Stud 18kt</label>
            <input value={s_18kt} type="number" id='s_18kt' className='form-control' onChange={(e) => {
                sets_18kt(e.target.value);
            }} />
                </div>
                <div className='col-2'>
                <button className='btn btn-primary mt-4' id="S_18kt" onClick={(evt) => updateprice(evt.target.id, s_18kt)}>Update</button>
                </div>
            </div>
        </div>
    </div>
}

export default Pricing;