import { useRef, useState } from "react"

export default () => {
    const username = useRef();
    const password = useRef();
    const [error, setError] = useState();
    const login = () => {
fetch('https://discovery.trishika.co/auth/login', {method: 'POST', headers: { 'Content-Type': 'application/json' },
body: JSON.stringify({username: username.current.value, password: password.current.value}), credentials: 'include'})
.then(res => res.json())
.then(jr => (jr.success) ? window.location.href = "/dashboard" : setError('Username or password is incorrect'));
    }
    return <>
    <div className="row mt-5">
        <div className="col-12 col-md-4 mx-auto">
            <h3>Trishika Admin Panel</h3>
            {(error) ? <div className="alert py-1 alert-danger">{error}</div> : <></>}
            <div className="form-group">
                <label htmlFor="username">Username</label>
            <input type="text" ref={username} id="username" className="form-control" />
            </div>
            <div className="form-group my-1">
                <label htmlFor="password">Password</label>
            <input type="password" ref={password} id="password" className="form-control" />
            </div>
            <div className="form-group">
            <button className="btn btn-primary" onClick={login}>Login</button>
            </div>
        </div>
    </div>
    </>
}
