import { useRef } from "react";

const Order = ({details}) => {
    let ti = useRef();
    let tl = useRef();
    let dt = (new Date(details.createdAt)).toDateString();
    let ux = details.uuid;
    dt += ' at ' + (new Date(details.createdAt)).toLocaleTimeString();
    
    const shipsend = (evt) => {
        if ((ti.current.value.length < 5) || (tl.current.value.length < 5)) {
            alert("Please enter correct order details"); return false;
        }
        evt.target.parentNode.parentNode.classList.add('bg-warning');
        evt.target.disabled = true;
        fetch("https://discovery.trishika.co/update-shipping", {credentials: 'include',
        headers: {'Content-type': 'application/json'}, method: 'post',
        body: JSON.stringify({ trackingid: ti.current.value, uuid: ux, trackingurl: tl.current.value })
    }).then(res => { if (res.ok) { window.location.reload();
    } else { alert('Something went wrong'); evt.target.disabled = false;
    evt.target.parentNode.parentNode.classList.remove('bg-warning'); } });
    }

    return <div className="pt-4 pb-1 px-3 border my-3 rounded shadow">
    <p>Order id: <span className="alert alert-secondary p-1 me-3">{details.uuid}</span>
    Placed on: <span className="alert alert-info p-1 mx-3">{dt}</span>
    Payment Status: {(details.isPaid)
    ? <span className="alert alert-success fw-bold p-1">PAID</span>
    : <span className="alert alert-danger fw-bold p-1">NOT PAID</span>}</p>
    <div className="row pt-3 border">
        <div className="col-4">
            <address>
                <h4>{details.address.name}</h4>
                <p>{details.address.address1}<br />{details.address.address2}<br />
                {details.address.city}<br />{`${details.address.state} - ${details.address.zipcode}`}</p>
                <p>Phone: <span className="ls-3">{details.address.phone}</span></p>
            </address>
        </div>
        <div className="col-4">
            <h4>₹ {details.total}</h4>
            <h5>Items ordered:</h5>
            {details.particulars.map((p, i) => <div key={i}>{p.item} x {p.itemquantity}</div>)}
        </div>
        <div className="col-4 mb-4">
            <h4>{(details.shippingStatus) ? 'Shipped' : 'NOT Shipped'}</h4>
            <div className="form-group">
            <label>Shipping Track ID</label>
            <input type="text" className="form-control" ref={ti} defaultValue={details.shippingTrackID} />
            </div>
            <div className="form-group my-3">
            <label>Shipping Tracking Link</label>
            <input type="text" className="form-control" ref={tl} defaultValue={details.shippingTrackLink} />
            </div>
            {(details.shippingStatus === 0) ? <button className="btn btn-primary" onClick={shipsend}>Save Tracking and Send Email</button> : 
            <button className="btn btn-danger" onClick={shipsend}>Update Tracking and Send Email</button>}
        </div>
    </div>
</div>
}

export default Order;